import React from "react";
import { Route, Switch } from "react-router-dom";
import styled from "styled-components";

import NoMatch from "./NoMatch.js";
import StatsCanParser from "../website_components/Portfolio/Projects/StatsCanParser.js";
import DNDGenerator from "../website_components/Portfolio/Projects/DNDGenerator.js";
import MemorySimulator from "../website_components/Portfolio/Projects/MemorySimulator.js";
import IMDBDatabase from "../website_components/Portfolio/Projects/IMDBDatabase.js";
import ProjectGutneberg from "../website_components/Portfolio/Projects/ProjectGutenberg.js";
import ContactList from "../website_components/Portfolio/Projects/ContactList.js";
import DealsNotifier from "../website_components/Portfolio/Projects/DealsNotifier.js";
import DecisionTree from "../website_components/Portfolio/Projects/DecisionTree.js";
import SquareSolver from "../website_components/Portfolio/Projects/SquareSolver.js";
import SVG_Editor from "../website_components/Portfolio/Projects/SVG_Editor.js";

const MyProjects = styled.div`
	width: 100%;

	.projectsContainer {
		min-height: 72vh;
		padding-top: 15vh;
		padding-bottom: 8vh;
		color: #555;
		font-size: 1.1em;
		font-family: "Lexend Exa", sans-serif;
	}

	.projectsImg {
		height: 36em;
		width: auto;
		margin-top: 1.5em;
		margin-bottom: 1.5em;
		max-width: 100%;
		border-style: solid;
	}

	h1 {
		font-size: 2em;
		font-weight: bold;
	}

	p {
		text-align: justify;
	}
`;

function Projects() {
	return (
		<MyProjects>
			<Switch>
				<Route
					exact
					path="/projects/deals_notifier"
					component={DealsNotifier}
				/>
				<Route
					exact
					path="/projects/decision_tree_learner"
					component={DecisionTree}
				/>
				<Route
					exact
					path="/projects/square_solver"
					component={SquareSolver}
				/>
				<Route
					exact
					path="/projects/svg_editor"
					component={SVG_Editor}
				/>
				<Route
					exact
					path="/projects/stats_can_parser"
					component={StatsCanParser}
				/>
				<Route
					exact
					path="/projects/dnd_level_generator"
					component={DNDGenerator}
				/>
				<Route
					exact
					path="/projects/memory_management_simulator"
					component={MemorySimulator}
				/>
				<Route
					exact
					path="/projects/imdb_database_analyzer"
					component={IMDBDatabase}
				/>
				<Route
					exact
					path="/projects/project_gutenberg_analyzer"
					component={ProjectGutneberg}
				/>
				<Route exact path="/projects/contact_list" component={ContactList} />
				<Route component={NoMatch} />
			</Switch>
		</MyProjects>
	);
}

export default Projects;
