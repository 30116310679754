import React from "react";
import styled from "styled-components";
import dealsNotifier from "../../../res/deal_notifier.png";
import Container from "react-bootstrap/Container";

const DealsNotifier_ = styled.section``;

function DealsNotifier() {
	return (
		<DealsNotifier_>
			<a class="anchor" id="top"></a>
			<Container className="projectsContainer text-center">
				<h1>Canadian Deals Notifier</h1>
				<img src={dealsNotifier} class="projectsImg"></img>
				<h3>Purpose</h3>
				<p>To notify Canadian users of deals that are of interest to them </p>
				<h4>About</h4>

				<p>
					This project was build using Kotlin using Android Studio.
					This was built collaborativley in a team environment, to learn more about it, <a
							target="_blank"
							rel="noopener noreferrer"
							href="https://github.com/mdombrovsky/deals-notifier"
						>  click this to visit the Github repo</a>.
				</p>
			</Container>
		</DealsNotifier_>
	);
}

export default DealsNotifier;
