import React from "react";
import styled from "styled-components";
import decisionTree from "../../../res/decision_tree_learner.png";
import Container from "react-bootstrap/Container";

const DecisionTree_ = styled.section``;

function DecisionTree() {
	return (
		<DecisionTree_>
			<a class="anchor" id="top"></a>
			<Container className="projectsContainer text-center">
				<h1>Decision Tree Machine Learning</h1>
				<img src={decisionTree} class="projectsImg"></img>
				<h3>Purpose</h3>
				<p>To build a decision tree based off of known information to assist artificial intelligence classify unkown information.</p>
				<h4>About</h4>

				<p>
                    This was built using Java.
                    <br></br>
					In the image above you can see a decision tree for classifing someones lending risk.
				</p>
			</Container>
		</DecisionTree_>
	);
}

export default DecisionTree;
