import React from "react";
import styled from "styled-components";
import portrait from "../../res/portrait.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";

const About = styled.section`
	#aboutSection {
		padding-top: 12vh;
		padding-bottom: 12vh;
		padding-right: 5vw;
		padding-left: 5vw;
	}

	#aboutHeader,
	#aboutDesc {
		color: black;
		font-family: "Lexend Exa", sans-serif;
	}
	#aboutHeader {
		text-align: center;
		line-height: max(0.8em, 1.3vw);
		font-size: min(6.5em, 12vw);
		font-weight: bold;
		padding-bottom: 1vh;
	}

	#aboutDesc {
		font-size: 1.8em;
		line-height: 1.4em;
	}

	#profilePicture {
		max-width: 100%;
		height: auto;
	}
`;
function AboutSection() {
	return (
		<About>
			<a class="anchor" id="about"></a>
			<Container id="aboutSection" fluid>
				<Row className="justify-content-center ">
					<h1 id="aboutHeader">About Me</h1>
				</Row>
				<Row>
					<Col
						xs={{ order: "last", span: false }}
						xl={{ order: "first", span: "4" }}
						className="d-block m-auto"
					>
						<Image
							id="profilePicture"
							className="d-block m-auto"
							src={portrait}
						/>
					</Col>
					<Col
						className="my-auto"
						xl={{ span: 8 }}
						className="d-block m-auto justify-content-center "
					>
						<p id="aboutDesc">
							<br />
							I am employed as an Associate Software Engineer at Veeva. I currently work on Full Stack development using Java and Javascript (React).
							<br />
							<br />
							I have completed a Software Engineering degree from the University of Guelph, where
							I have won awards for being at the top of my class (
							<a
								href="https://www.uoguelph.ca/registrar/studentfinance/apps/awards?id=I0561"
								target="_blank"
								rel="noopener noreferrer"
							>
								Dr. Ted Swart Scholarship
							</a>
							&nbsp;and&nbsp;
							<a
								href="https://www.uoguelph.ca/registrar/studentfinance/apps/awards?id=I0560"
								target="_blank"
								rel="noopener noreferrer"
							>
								Dr. Mary McLeish Scholarship
							</a>
							).
							<br />
							<br />
						</p>
					</Col>
				</Row>
			</Container>
		</About>
	);
}
export default AboutSection;
