import React from "react";
import WrongURL from "../website_components/Error/WrongURL.js";

function NoMatch() {
	return (
		<div>
			<WrongURL />
		</div>
	);
}

export default NoMatch;
