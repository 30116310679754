import React from "react";
import styled from "styled-components";
import contacts from "../../../res/contacts.png";
import Container from "react-bootstrap/Container";

const ContactList_ = styled.section``;

function ContactList() {
	return (
		<ContactList_>
			<a class="anchor" id="top"></a>
			<Container className="projectsContainer text-center">
				<h1>Persistent Contact List using binary database</h1>
				<img src={contacts} class="projectsImg"></img>
				<h3>Purpose</h3>
				<p>To store and retrieve contacts.</p>
				<h4>About</h4>

				<p>
					This project was build using C using Visual Studio Code.
					<br></br>
					This program used efficient storage methods to save contacts, and used
					quicksort to sort the contacts in a highly efficient manner.
				</p>
			</Container>
		</ContactList_>
	);
}

export default ContactList;
