import React from "react";
import styled from "styled-components";

const WrongURLSection = styled.section`
	#wrongURLContainer {
		height: 35vh;
	}
	#wrongURLMessage {
		margin-top: 47vh;
	}
	h1 {
		text-align: center;
		font-size: 6vh;
		line-height: 7vh;
	}
`;
function WrongURL() {
	return (
		
		<WrongURLSection>
			<div id="wrongURLContainer">
				<div id="wrongURLMessage">
					<h1>Oops you seem to have entered a non existent link</h1>
				</div>
			</div>
		</WrongURLSection>
	);
}

export default WrongURL;
