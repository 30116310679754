import React from "react";
import styled from "styled-components";
import squareSolver from "../../../res/solver_8_square_puzzle.png";
import Container from "react-bootstrap/Container";

const SquareSolver_ = styled.section``;

function SquareSolver() {
	return (
		<SquareSolver_>
			<a class="anchor" id="top"></a>
			<Container className="projectsContainer text-center">
				<h1>8 Square Solver</h1>
				<img src={squareSolver} class="projectsImg"></img>
				<h3>Purpose</h3>
				<p>To solve an 8 Square puzzle using the A* search algorithm utilizing a greedy heuristic function.</p>
				<h4>About</h4>

				<p>
                    This was built using Java.
                    <br></br>
					In the image above you can see all the nodes the algorithm goes to in attempting to solve the puzzle, including their heuristic values.
				</p>
			</Container>
		</SquareSolver_>
	);
}

export default SquareSolver;
