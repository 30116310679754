import React from "react";
import styled from "styled-components";
import DND from "../../../res/DND.png";
import Container from "react-bootstrap/Container";

const DNDGen = styled.section``;

function DNDGenerator() {
	return (
		<DNDGen>
			<a class="anchor" id="top"></a>

			<Container className="projectsContainer text-center">
				<h1>Dungeons and Dragons level generator</h1>
				<img src={DND} class="projectsImg"></img>
				<h3>Purpose</h3>
				<p>
					To generate an enviroment for the popular role playing game Dungeons
					and Dragons.
				</p>
				<h4>About</h4>

				<p>
					This project was built using Java using Intellij IDE.
					<br></br>
					The program leverages a database to get game assests (such as
					Monsters) so that new types of assests may be added easilly.
				</p>
			</Container>
		</DNDGen>
	);
}

export default DNDGenerator;
