import React, { useEffect } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";

import ReactGA from 'react-ga';

import NavbarSection from "../website_components/Main/NavbarSection.js";
import Footer from "../website_components/Main/FooterSection.js";
import Landing from "./Landing.js";
import Projects from "./Projects.js";
import NoMatch from "./NoMatch.js";
import Geoware from "../website_components/Portfolio/Experience/Geoware";
import NCR from "../website_components/Portfolio/Experience/NCR";
import Intuit from "../website_components/Portfolio/Experience/Intuit";



function App() {
	useEffect(() => {
		ReactGA.initialize('UA-203131043-1', {
			gaOptions: {
				siteSpeedSampleRate: 100
			}
		});

		//This is to track what pages users visit
		var str = window.location.hash
		//We get rid of everything before the first hash (we are using hashrouter)
		str = str.substring(str.indexOf("#") + 1);
		//Then pass it to Google Analytics
		ReactGA.pageview(str)

	}, [])

	return (
		<HashRouter onUpdate={() => window.scrollTo(0, 0)}>
			<NavbarSection />
			<Switch>
				<Route exact path={["/"]} component={Landing} />
				<Route path="/projects" component={Projects} />
				<Route path="/geoware" component={Geoware} />
				<Route path="/ncr" component={NCR} />
				<Route path="/intuit" component={Intuit} />

				<Route component={NoMatch} />
			</Switch>
			<Footer />
		</HashRouter>
	);
}

export default App;
