import React from "react";
import styled from "styled-components";
import IMDB from "../../../res/IMDB.png";
import Container from "react-bootstrap/Container";

const IMDBData = styled.section``;

function IMDBDatabase() {
	return (
		<IMDBData>
			<a class="anchor" id="top"></a>
			<Container className="projectsContainer text-center">
				<h1>IMDB database analyzer</h1>
				<img src={IMDB} class="projectsImg"></img>
				<h3>Purpose</h3>
				<p>
					To search the IMDB database for an actors name and find corresponding
					movies or to search for a movie title and get the corresponding
					leading actors.
				</p>
				<h4>About</h4>

				<p>
					This project was build using C using Visual Studio Code.
					<br></br>
					This program leverages appropriate data structures in order to achieve
					O(log(n)) runtime during search.
				</p>
			</Container>
		</IMDBData>
	);
}

export default IMDBDatabase;
