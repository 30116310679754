import React from "react";
import styled from "styled-components";
import statsCan from "../../../res/Stats Canada Parser.jpg";
import Container from "react-bootstrap/Container";

const StatsParser = styled.section``;

function StatsCanParser() {
	return (
		<StatsParser>
			<a class="anchor" id="top"></a>
			<Container className="projectsContainer text-center">
				<h1>Statistics Canada Parser</h1>
				<h2>Consumer Price Index CSV Parser</h2>
				<img src={statsCan} class="projectsImg"></img>
				<h3>Purpose</h3>
				<p>
					To analyze hundreds of thousands of data points from the Statistics
					Canada Database on the prices of goods and services in order to make
					informed estimates on future price trends.
				</p>
				<h4>About</h4>

				<p>
					This project was done in a team environment using the Perl programming
					language and Agile programming methodologies,
					<br></br>
					The program is able to efficiently parse the Statiscs Canada CPI
					database relating to different categories and transfer those results
					into graphs.
					<br></br>
					The program worked by implementing strategies for reading in large
					files efficiently through preforming calculations in real time as the
					data is being read in.
				</p>
			</Container>
		</StatsParser>
	);
}

export default StatsCanParser;
