import React from "react";
import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";

import geowareLogo from "../../../res/geoware_logo.png";
import geowareLocation from "../../../res/geoware_location.png";
import wfh from "../../../res/wfh.png";

const Geoware_ = styled.section`
	#geowareSection {
		font-family: "Lexend Exa", sans-serif;
		padding-top: 13.5vh;
		padding-bottom: 7vh;
		word-wrap: break-word;
	}
	hr {
		margin-top: 3vh;
		margin-bottom: 3vh;
	}
	h2 {
		font-weight: bold;
		font-size: 2.25em;
	}
	p {
		text-align: justify;
	}
	li {
		text-align: left;
	}
`;

function Geoware() {
	return (
		<Geoware_>
			<a class="anchor" id="top"></a>

			<div id="geowareSection">
				<Container>
					<h2>Introduction</h2>

					<p>
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;Greetings, this post is about sharing and
						documenting my experience at GEOWARE as a Software Developer, from
						May 2020 to December 2020.
					</p>

					<hr />

					<Container className="p-0">
						<Row>
							<Col lg="3" className="m-auto">
								<Image src={geowareLogo} className="m-auto" width="100%" />
							</Col>
							<Col
								xs={{ order: "first", span: false }}
								lg={{ order: "last", span: "9" }}
								className="m-auto"
							>
								<h2>Information about the Employer</h2>

								<p>
									<br />
									&nbsp;&nbsp;&nbsp;&nbsp;GEOWARE is a Canadian company that was
									founded in 1983 with the goal of providing software solutions
									to the solid waste industry. They provide two main software
									packages, site operator software that processes customer
									transactions, and management software that has the ability to
									track inventory, generate reports, create invoices, configure
									operator software, and a variety of other functionality.
								</p>
							</Col>
						</Row>
					</Container>

					<hr />



					<Container fluid>
						<Row>
							<Col lg="10" className="m-auto p-0">
								<h2>Working remotely</h2>
								<p>
									<br />
									&nbsp;&nbsp;&nbsp;&nbsp;My coop terms were spent working from
									home. Working remotely presented a few new challenges, such as
									difficulty in onboarding. From my experience I learned that
									communication is paramount to succeeding in such an
									environment.
								</p>
							</Col>
							<Col className="m-auto p-0">
								<Image
									src={wfh}
									width="100%"
									className="m-auto"
									height="auto"
								></Image>
							</Col>
						</Row>
					</Container>

					<hr />

					<h2>Job Description</h2>
					<p>
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;At GEOWARE, I worked on product development
						for the brand new flagship R15 management solution. The majority of
						my time was spent maintaining and improving the reporting and
						threading functionality of the application.
						<br />
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;One of the more exciting tasks I undertook
						was when I was able to apply the theory I learned in school to
						solving real world problems. For example, I had to leverage my
						extensive knowledge of threading in order to modify the existing
						threading functionality to properly implement the cancellation of
						tasks (that have been cancelled through the cancel button).
						<br />
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;One of my favourite activities was finding
						where the application could be optimized, creating a plan for
						speeding it up, and then implementing it. I was able to shorten
						report opening times by a factor of 6 by getting rid of duplicate
						meta data, and I also managed to increase read performance of
						customer data by 98.90% through Hibernate optimization.
						<br />
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;My biggest effort was adding on-demand
						loading to all the reports. Before this data would be loaded
						regardless of whether it was needed or not, and even if it was
						loaded it would be loaded again if it needed to be used. I managed
						to streamline this process by making sure that data is only loaded
						when it is needed, and is only reloaded when it has changed. As this
						was too big an effort for me to complete on my own, I created
						documentation that contained a guide so that other developers would
						be able to assist me in implementing this feature. Overall, I
						oversaw it being carried out from start to finish. This resulted in
						faster report opening times, and an overall smoother experience when
						using reports.
					</p>

					<hr />

					<h2>Conclusions</h2>
					<p>
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;Overall, I believe that my experience at
						GEOWARE was overwhelmingly positive, and helped me to grow as a
						developer, particularly in the fields of collaboration, and
						optimization.
					</p>

					<hr />

					<Container className="p-0">
						<Row>
							<Col className="m-auto">
								<Image
									src={geowareLocation}
									className="m-auto"
									width="100%"
								></Image>
							</Col>
							<Col
								xs={{ order: "first", span: false }}
								lg={{ order: "last", span: "8" }}
								className="m-auto"
							>
								<h2>Acknowledgments</h2>
								<p>
									<br />
									&nbsp;&nbsp;&nbsp;&nbsp;I would not have been able to succeed
									to the degree that I have throughout this coop term, if it was
									not for the wonderful onboarding that was provided by my
									supervisor Ciprian Bulboaca and a senior developer Attila
									Palfi. Huge thanks to them.
								</p>
							</Col>
						</Row>
					</Container>
				</Container>
			</div>
		</Geoware_>
	);
}

export default Geoware;
