import React from "react";
import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import intuitLogo from "../../../res/intuit_logo.svg";
import intuitLocation from "../../../res/1042px-2700coastavenue.jpg";
import wfh from "../../../res/wfh.jpg";

const Intuit_ = styled.section`
	#section {
		font-family: "Lexend Exa", sans-serif;
		padding-top: 13.5vh;
		padding-bottom: 7vh;
		word-wrap: break-word;
	}
	hr {
		margin-top: 3vh;
		margin-bottom: 3vh;
	}
	h2 {
		font-weight: bold;
		font-size: 2.25em;
	}
	p {
		text-align: justify;
		margin-right: 2em ;
	}
	li {
		text-align: left;
	}
`;

const Intuit = () => {
	return (
		<Intuit_>
			<a class="anchor" id="top"></a>

			<div id="section">
				<Container>


					<Container className="p-0">
						<Row>

							<Col
								xs={{ order: "first", span: false }}
								lg={{ order: "first", span: "9" }}
								className="m-auto"
							>
								<h2>Introduction</h2>

								<p>
									<br />
									&nbsp;&nbsp;&nbsp;&nbsp;Greetings, my name is Michael Dombrovsky, and this post is about my experience at Intuit as an Android Software Developer, from May 2022 to August 2022.
								</p>

								<hr />
								<h2>Information about the Employer</h2>

								<p>
									<br />
									&nbsp;&nbsp;&nbsp;&nbsp;Intuit Inc. is an American based software company founded in 1983. It is focused on providing software in the financial sphere to individual and business customers. I was primarily involved in the tax return segment of the business.
								</p>

							</Col>
							<Col lg="3" className="m-auto">
								<Image src={intuitLogo} className="m-auto" fluid />
							</Col>
						</Row>
					</Container>



					<hr />



					<h2>Job Description</h2>
					<p>
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;During my 4 month internship at Intuit, I was responsible for building and providing support for two Android libraries that are utilized by the TurboTax mobile application. Technologies that were used included the Kotlin language, as well as the newly released Player framework.
						<br />
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;Throughout my co-op, while working on library development, I was exposed to important concepts such as the need to maintain backwards compatibility outside of major version (within semantic versioning) changes, thereby making it easier for applications to upgrade their version of the library.
						<br />
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;As well, a common theme was to plan out development thoroughly to minimize major version changes. A reason for this is because it can be time consuming and challenging to perform major version upgrades. I saw this in action while being on the other side of the process, having to upgrade library versions. For example, in order to upgrade a certain dependency from 0.X.X to 1.X.X, I had to review the changelog and do research on what the new way of using the dependency was, and then implement those changes throughout the application.
						<br />
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;I also gained a better understanding of the value of documentation, in that it can save time for both those who develop the library, and those that use it. I had first hand experience seeing this principle in action as well. For example, while upgrading a more key internal dependency, it was not clear how to perform a major version upgrade, I had to reach out to maintainers for advice on how to continue many time, resulting in this process becoming rather time consuming due to both the extent of the changes, and communicating between different time zones.
						<br />
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;Throughout the term, I also gained experience responding to SLA’s (service level agreements) with other teams that used the libraries, helping them through issues they faced with the libraries. This mostly involved investigating bugs, attempting to reproduce them, and then running canary builds to verify that the fixes work, as well as helping teams use the library in their projects.
						<br />
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;This co-op term gave me valuable insight into an area of software development I have not had much experience with before.
					</p>

					<hr />

					<Container className="p-0">
						<Row>
							<Col className="m-auto">
								<Image id="location"
									src={intuitLocation}
									className="m-auto"
									width="100%"
									title="This image was taken by user Coolcaesar"
								></Image>
							</Col>
							<Col
								xs={{ order: "first", span: false }}
								lg={{ order: "last", span: "8" }}
								className="m-auto"
							>
								<h2>Conclusions</h2>
								<p>
									<br />
									&nbsp;&nbsp;&nbsp;&nbsp;Working at Intuit was a significant milestone in my professional development. It allowed me to learn about best practices in utilizing and developing libraries. I gained valuable experience interacting with other teams, getting a better understanding of how various departments contribute to overall success of the company. Overall, I believe that my co-op term at Intuit was very beneficial, allowing me to become a more well rounded software developer.
								</p>
							</Col>
						</Row>
					</Container>
					<hr />

					<h2>Acknowledgments</h2>
					<p>
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;Lastly, I wish to express my gratitude to Paul Millerd for being a great mentor to me, and Oscar Menjivar for helping me resolve the numerous road blocks I encountered along the way.
					</p>


				</Container>
			</div>
		</Intuit_>
	);
}

export default Intuit;
