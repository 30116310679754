import React from "react";
import styled from "styled-components";
import svg from "../../../res/svg_editor.png";
import Container from "react-bootstrap/Container";

const SVG_Editor_ = styled.section`
`;

function SVG_Editor() {
	return (
		<SVG_Editor_>
			<a class="anchor" id="top"></a>
			<Container className="projectsContainer text-center">
				<h1>SVG Editor</h1>
				<img src={svg} class="projectsImg" ></img>
				<h3>Purpose</h3>
				<p>To create and edit SVG images that are stored on a server from a web browser.</p>
				<h4>About</h4>
				<p>
                    This is a full stack application. The front end is built using JavaScript and Bootstrap. The back end is built using Node.js, JavaScript, and C.
				</p>
			</Container>
		</SVG_Editor_>
	);
}

export default SVG_Editor;
