import React from "react";
import styled from "styled-components";
import array_list from "../../../res/Array vs List.jpg";
import Container from "react-bootstrap/Container";

const MemorySim = styled.section``;

function MemorySimulator() {
	return (
		<MemorySim>
			<a class="anchor" id="top"></a>
			<Container className="projectsContainer text-center">
				<h1>Memory Management Simulator</h1>
				<img src={array_list} class="projectsImg"></img>
				<h3>Purpose</h3>
				<p>
					To explore the space and time complexitites of both arrays and linked
					list and understand both on much more higher level.
				</p>
				<h4>About</h4>

				<p>
					This project was done using C to simulate the memory management, and
					then the data from those simulations was plotted using R Statistics
					software.
					<br></br>
					The program is able to simulate memory management on the most basics
					level for both arrays and linked list
					<br></br>
					Afterwards box plots were created using R on space and time
					complexitites for a wide variety of scenerios.
				</p>
			</Container>
		</MemorySim>
	);
}

export default MemorySimulator;
