import React from "react";
import styled from "styled-components";
import { HashLink as Link } from "react-router-hash-link";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import logo from "../../res/logo.png";

const NavBarSection = styled.nav`
	#stickyTop {
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 99;
		background-color: rgba(0, 0, 0, 0.6);
	}

	#navbar {
		background-color: rgba(0, 0, 0, 0.85);
		padding-top: 2vh;
		padding-bottom: 2vh;
	}

	.navLink {
		color: white;
		font-size: 18px;
		line-height: 1.6em;
		font-family: "Lexend Exa", sans-serif;
		padding: 0 0.75vw;
		margin: 0;
	}

	#logo {
		height: max(40px, 3.5vw);
		width: auto;
		padding: 0;
		margin: 0;
		padding-left: 1vw;
	}

	#navBrand {
		padding: 0;
		margin: 0;
	}
`;
function NavbarSection() {
	return (
		<NavBarSection>
			<Navbar id="navbar" expand="md" variant="dark" fixed="top">
				<Link to="/#">
					<img src={logo} id="logo" />
				</Link>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav className="align-content-center ml-auto">
						<Nav.Link>
							<Link className="navLink" to="/#home">
								Home
							</Link>
						</Nav.Link>
						<Nav.Link>
							<Link className="navLink" to="/#about">
								About
							</Link>
						</Nav.Link>
						<Nav.Link>
							<Link className="navLink" to="/#experience">
								Experience
							</Link>
						</Nav.Link>
						<Nav.Link>
							<Link className="navLink" to="/#projects">
								Projects
							</Link>
						</Nav.Link>
						<Nav.Link>
							<Link className="navLink" to="#contact">
								Contact
							</Link>
						</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</NavBarSection>
	);
}

export default NavbarSection;
