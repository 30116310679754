import React from "react";
import styled from "styled-components";
import space from "../../res/space.jpg";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import { HashLink as Link } from "react-router-hash-link";

import geowareLogo from "../../res/geoware_logo.png";
import ncrLogo from "../../res/ncr_logo.svg";
import intuitLogo from "../../res/intuit_logo.svg";

const Experience = styled.section`
	#experienceSection {
		background-image: url(${space});
		font-family: "Lexend Exa", sans-serif;

		background-attachment: fixed;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;

		width: 100%;
		padding-top: 12vh;
		padding-bottom: 2vh;
	}

	.experienceCard {
		width: 80vw;
		margin: auto;
		font-size: 1.3em;
		margin-bottom: 5vh;
	}
	.logo {
		padding: 20px;
	}

	#experienceHeader {
		font-size: min(6em, 10vw);
		font-weight: bold;
		padding-bottom: 9vh;
	}
`;

function ExperienceSection() {
	return (
		<Experience>
			<a class="anchor" id="experience"></a>

			<div id="experienceSection">
				<h1 id="experienceHeader" class="text-white text-center">
					Experience
				</h1>
				<Card border="secondary" className="text-center experienceCard">
					<Card.Header>
						<h2 class="m-2 ">Mobile Software Developer at Intuit</h2>
					</Card.Header>
					<Card.Body>
						<Container fluid>
							<Row>
								<Col lg="4" className="m-auto">
									<Image src={intuitLogo} className="logo" fluid />
								</Col>
								<Col lg="8" className="m-auto">
									<ul class="text-left">
										<li>
											Worked on an in-house Android UI library used by the TurboTax tax software
										</li>
										<li>
											Developed cache management system for variable size lists of components that allowed for smoother screen animations
										</li>
										<li>
											Diagnosed and resolved bugs in downstream applications using canary builds
										</li>
									</ul>
								</Col>
							</Row>
						</Container>
					</Card.Body>
					<Card.Link className="mx-auto mb-4">
						<Link to="/intuit/#top" className="btn-primary m-auto p-2">
							View full report here
						</Link>
					</Card.Link>

					<Card.Footer className="text-muted">
						May 2022 - August 2022
					</Card.Footer>
				</Card>
				<Card border="secondary" className="text-center experienceCard">
					<Card.Header>
						<h2 class="m-2 ">Front-End Software Developer at NCR</h2>
					</Card.Header>
					<Card.Body>
						<Container fluid>
							<Row>
								<Col lg="4" className="m-auto">
									<Image src={ncrLogo} className="logo" fluid />
								</Col>
								<Col lg="8" className="m-auto">
									<ul class="text-left">
										<li>
											Maintained and improved the NCR bank teller front end user interface
										</li>
										<li>
											Implemented dynamic theming allowing the software to be sold to various customers with minimal modifications
										</li>
										<li>
											Led effort to create a reusable hotkey help dialog to familiarize users with shortcut usage
										</li>
									</ul>
								</Col>
							</Row>
						</Container>
					</Card.Body>
					<Card.Link className="mx-auto mb-4">
						<Link to="/ncr/#top" className="btn-primary m-auto p-2">
							View full report here
						</Link>
					</Card.Link>

					<Card.Footer className="text-muted">
						January 2022 - April 2022
					</Card.Footer>
				</Card>
				<Card border="secondary" className="text-center experienceCard">
					<Card.Header>
						<h2 class="m-2 ">Software Developer at GEOWARE</h2>
					</Card.Header>
					<Card.Body>
						<Container fluid>
							<Row>
								<Col lg="4" className="m-auto">
									<Image src={geowareLogo} className="border logo" fluid />
								</Col>
								<Col lg="8" className="m-auto">
									<ul class="text-left">
										<li>
											Maintained reporting and threading functionality for
											flagship GEOWARE R15 software
										</li>
										<li>
											Improved data access using Hibernate framework, decreasing
											latency by 98.90%
										</li>
										<li>
											Optimized reports by adding caching and eliminating
											superfluous code segments, reducing total loading time by
											a factor of 6
										</li>
									</ul>
								</Col>
							</Row>
						</Container>
					</Card.Body>
					<Card.Link className="mx-auto mb-4">
						<Link to="/geoware/#top" className="btn-primary m-auto p-2">
							View full report here
						</Link>
					</Card.Link>

					<Card.Footer className="text-muted">
						May 2020 - December 2020
					</Card.Footer>
				</Card>

			</div>
		</Experience>
	);
}

export default ExperienceSection;
