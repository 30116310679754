import React from "react";

import HomeSection from "../website_components/Main/HomeSection";
import AboutSection from "../website_components/Main/AboutSection";
import ExperienceSection from "../website_components/Portfolio/ExperienceSection";
import ProjectSection from "../website_components/Portfolio/ProjectSection";

function Landing() {
	return (
		<div>
			<HomeSection />
			<AboutSection />
			<ExperienceSection />
			<ProjectSection />
		</div>
	);
}

export default Landing;
