import React from "react";
import styled from "styled-components";
import gutenberg from "../../../res/Gutenberg.png";
import Container from "react-bootstrap/Container";

const ProjectGutenberg_ = styled.section``;

function ProjectGutneberg() {
	return (
		<ProjectGutenberg_>
			<a class="anchor" id="top"></a>
			<Container className="projectsContainer text-center">
				<h1>Analysis of books from Project Gutenberg</h1>
				<img src={gutenberg} class="projectsImg"></img>
				<h3>Purpose</h3>
				<p>
					To analyze different novels from Project Gutenberg among several
					metrics inclduing length, the amount of unique words, and the
					occurences of a particular word.
				</p>
				<h4>About</h4>

				<p>
					This project was build using C using Visual Studio Code.
					<br></br>
					This program leverages appropriate data management techniques
					including creating shallow copies as well as deep copies where
					necessary in order to maintain efficiency.
				</p>
			</Container>
		</ProjectGutenberg_>
	);
}

export default ProjectGutneberg;
