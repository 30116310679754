import React from "react";
import styled from "styled-components";
import forest from "../../res/forest.jpg";

const MyHome = styled.section`
	#homeSection {
		background-image: url(${forest});

		width: 100%;
		min-height: 100vh;

		background-attachment: fixed;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;

		padding-top: 0;
		padding-bottom: 0;
	}

	h1,
	h2 {
		color: #def4ff;
		font-weight: bold;
	}

	h1 {
		font-size: min(16vh, 25vw);
		font-family: "Amatic SC", cursive;
		line-height: 0.9em;
		margin: 0;
	}

	h2 {
		font-size: min(5vh, 6vw);
		color: rgb(244, 245, 239);
		font-family: "Lexend Exa", sans-serif;
		line-height: 1.6em;
	}

	#homeTextContainer {
		height: 100vh;
		width: 80vw;
		margin: auto;
	}
`;

function HomeSection() {
	return (
		<MyHome>
			<div id="homeSection" class="text-white">
				<a class="anchor" id="home"></a>

				<table id="homeTextContainer">
					<tbody>
						<tr>
							<td class="align-middle">
								<div class="text-center">
									<br />
									<h1 class="h1">Hello, I'm Michael Dombrovsky</h1>
									<br />
									<h2 class="h2">
										Software Engineer

									</h2>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</MyHome>
	);
}

export default HomeSection;
