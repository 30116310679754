import React from "react";
import styled from "styled-components";
import statsCan from "../../res/Stats Canada Parser.jpg";
import DND from "../../res/DND.png";
import array_list from "../../res/Array vs List.jpg";
import IMDB from "../../res/IMDB.png";
import svg from "../../res/svg_editor.png";
import { HashLink as Link } from "react-router-hash-link";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import dealsNotifier from "../../res/deal_notifier.png";
import decisionTreeLearner from "../../res/decision_tree_learner.png"
import squareSolver from "../../res/solver_8_square_puzzle.png"

const Projects = styled.section`
	#projectSection {
		padding-top: 12vh;
		padding-bottom: 12vh;
		padding-right: 3.5vw;
		padding-left: 3.5vw;
		font-family: "Lexend Exa", sans-serif;
	}

	.projectImage {
		min-width: min(20em, 30vw);
		height: 25em;
		max-width: 80%;
		margin: auto;
		object-fit: cover;
		object-position: 50% 25%;
	}

	.projectCard {
		margin: 2em;
		padding-bottom: 2em;
		padding-top: 2em;
		height: 85%;

		background-color: rgb(128, 128, 64);
		color: white;
	}

	#projectsHeader {
		font-size: min(6em, 10vw);
		font-weight: bold;
		padding-bottom: 4vh;
	}


`;

function ProjectSection() {
	return (
		<Projects>
			<a class="anchor" id="projects"></a>

			<div id="projectSection">
				<h1 id="projectsHeader" class="text-center">
					Projects
				</h1>
				<Container fluid>
					<Row>
						<Col>
							<Card className="projectCard">
								<Card.Img
									variant="top"
									src={dealsNotifier}
									className="  projectImage"
								/>
								<Card.Body className="m-auto">
									<Card.Title className="text-center">
										Canadian Deals Notifier
									</Card.Title>
								</Card.Body>
								<Card.Link className="mx-auto pt-4  ">
									<Link
										to="/projects/deals_notifier/#top"
										className="btn-primary p-2"
									>
										Learn More
									</Link>
								</Card.Link>
							</Card>
						</Col>
						<Col>
							<Card className="projectCard">
								<Card.Img
									variant="top"
									src={decisionTreeLearner}
									className="  projectImage"
								/>
								<Card.Body className="m-auto">
									<Card.Title className="text-center">
										Decision Tree Machine Learning
									</Card.Title>
								</Card.Body>
								<Card.Link className="mx-auto pt-4 ">
									<Link
										to="/projects/decision_tree_learner/#top"
										className="btn-primary p-2 "
									>
										Learn More
									</Link>
								</Card.Link>
							</Card>
						</Col>
						<Col>
							<Card className="projectCard">
								<Card.Img
									variant="top"
									src={statsCan}
									className="  projectImage"
								/>
								<Card.Body className="m-auto">
									<Card.Title className="text-center">
										Statistics Canada Parser
									</Card.Title>
								</Card.Body>
								<Card.Link className="mx-auto pt-4  ">
									<Link
										to="/projects/stats_can_parser/#top"
										className="btn-primary p-2 "
									>
										Learn More
									</Link>
								</Card.Link>
							</Card>
						</Col>
						<Col>
							<Card className="projectCard">
								<Card.Img variant="top" src={DND} className="  projectImage" />
								<Card.Body className="m-auto">
									<Card.Title className="text-center">
										Dungeons and Dragons level generator
									</Card.Title>
								</Card.Body>
								<Card.Link className="mx-auto pt-4  ">
									<Link
										to="/projects/dnd_level_generator/#top"
										className="btn-primary p-2 "
									>
										Learn More
									</Link>
								</Card.Link>
							</Card>
						</Col>
						<Col>
							<Card className="projectCard">
								<Card.Img
									variant="top"
									src={squareSolver}
									className="  projectImage"
								/>
								<Card.Body className="m-auto">
									<Card.Title className="text-center">
										8 Square Solver
									</Card.Title>
								</Card.Body>
								<Card.Link className="mx-auto pt-4  ">
									<Link
										to="/projects/square_solver/#top"
										className="btn-primary p-2 "
									>
										Learn More
									</Link>
								</Card.Link>
							</Card>
						</Col>
						<Col>
							<Card className="projectCard">
								<Card.Img
									variant="top"
									src={array_list}
									className="  projectImage"
								/>
								<Card.Body className="m-auto">
									<Card.Title className="text-center">
										Memory management simulator
									</Card.Title>
								</Card.Body>
								<Card.Link className="mx-auto pt-4  ">
									<Link
										to="/projects/memory_management_simulator/#top"
										className="btn-primary p-2 "
									>
										Learn More
									</Link>
								</Card.Link>
							</Card>
						</Col>

						<Col>
							<Card className="projectCard">
								<Card.Img variant="top" src={IMDB} className="  projectImage" />
								<Card.Body className="m-auto p-auto">
									<Card.Title className="text-center">
										IMDB database analyzer
									</Card.Title>
								</Card.Body>
								<Card.Link className="mx-auto pt-4  ">
									<Link
										to="/projects/imdb_database_analyzer/#top"
										className="btn-primary p-2 "
									>
										Learn More
									</Link>
								</Card.Link>
							</Card>
						</Col>
						<Col>
							<Card className="projectCard">
								<Card.Img
									variant="top"
									src={svg}
									className="  projectImage"
								/>
								<Card.Body className="m-auto">
									<Card.Title className="text-center">
										SVG editor
									</Card.Title>
								</Card.Body>
								<Card.Link className="mx-auto pt-4  ">
									<Link
										to="/projects/svg_editor/#top"
										className="btn-primary p-2 "
									>
										Learn More
									</Link>
								</Card.Link>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</Projects>
	);
}

export default ProjectSection;
