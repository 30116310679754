import React from "react";
import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import ncrLogo from "../../../res/NCR_Corporation_logo.svg";
import ncrLocation from "../../../res/ncr_location.png";
import wfh from "../../../res/wfh.jpg";

const NCR_ = styled.section`
	#section {
		font-family: "Lexend Exa", sans-serif;
		padding-top: 13.5vh;
		padding-bottom: 7vh;
		word-wrap: break-word;
	}
	hr {
		margin-top: 3vh;
		margin-bottom: 3vh;
	}
	h2 {
		font-weight: bold;
		font-size: 2.25em;
	}
	p {
		text-align: justify;
		margin-right: 2em ;
	}
	li {
		text-align: left;
	}

`;

const NCR = () => {
	return (
		<NCR_>
			<a class="anchor" id="top"></a>

			<div id="section">
				<Container>
					<h2>Introduction</h2>

					<p>
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;Greetings, my name is Michael Dombrovsky, and this post is about my experience at
						NCR as a Front-end Software Developer, from January 2022 to April 2022.
					</p>

					<hr />

					<Container className="p-0">
						<Row>
							<Col lg="3" className="m-auto">
								<Image src={ncrLogo} className="m-auto" width="100%" />
							</Col>
							<Col
								xs={{ order: "first", span: false }}
								lg={{ order: "last", span: "9" }}
								className="m-auto"
							>
								<h2>Information about the Employer</h2>

								<p>
									<br />
									&nbsp;&nbsp;&nbsp;&nbsp;NCR (National Cash Register) Corporation is an American company that was founded in
									1884. These days they are focusing on providing services to assist in day to day financial
									happenings, such as their self checkout machines.
								</p>
							</Col>
						</Row>
					</Container>
					<hr />

					<Container fluid>
						<Row>
							<Col lg="10" className="m-auto p-0">
								<h2>Working remotely</h2>
								<p>
									<br />
									&nbsp;&nbsp;&nbsp;&nbsp;My co-op term started onboarding remotely. Thanks to my previous experience in
									working remotely, I have been able to succeed and thrive in this environment at NCR.
								</p>
							</Col>
							<Col className="m-auto p-0">
								<Image
									src={wfh}
									width="100%"
									className="m-auto"
									height="auto"
								></Image>
							</Col>
						</Row>
					</Container>

					<hr />

					<h2>Job Description</h2>
					<p>
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;During my 4 month internship at NCR, I worked on improving and building the front-end
						bank teller software in React.
						<br />
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;A major effort I undertook, has been to hook up the front end theming to the back end
						configuration so that the teller software can be sold to many separate customers with different
						branding, potentially helping to generate millions in recurring revenue.
						<br />
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;I have gained considerable experience in creating new components from scratch,
						especially how to do so in a reusable manner. One such example is when I have been tasked with
						creating a modal to display various hotkey bindings (such as f1 to open help). Even though it was
						not required, I immediately thought about how to do so in a reusebable manner, making sure to
						separate the hotkey bindings from the styling and passing in its configuration from an external
						file. This move towards reusability has paid off, as over time many key bindings have been
						changed, some were even removed, this effort was as simple as changing a single line in the
						configuration file, without any worry about modifying the actual hotkeys component. Eventually
						it was decided that since the component was very reusable, it would go into a special shared
						package so that other NCR software projects could use it as well.
						<br />
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;Throughout the work term, I have seen firsthand how software development best
						practices that I have learned throughout my academic career are applied in a real work
						environment. For example, the practice of creating unit and integration tests, after components
						are created, have been paramount in letting me know that I made a mistake I did not intend to,
						that would have otherwise gone uncaught.
					</p>

					<hr />

					<h2>Conclusions</h2>
					<p>
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;Overall, I believe that my experience at NCR was very useful in understanding how
						software development functions at large corporations. This has greatly improved my skills as a
						software developer, particularly in front-end software development
					</p>

					<hr />

					<Container className="p-0">
						<Row>
							<Col className="m-auto">
								<Image id="location"
									src={ncrLocation}
									className="m-auto"
									width="100%"
								></Image>
							</Col>
							<Col
								xs={{ order: "first", span: false }}
								lg={{ order: "last", span: "8" }}
								className="m-auto"
							>
								<h2>Acknowledgments</h2>
								<p>
									<br />
									&nbsp;&nbsp;&nbsp;&nbsp;Lastly, I would like to express my gratitude to my supervisor Alex Treude, and my team
									leader Lubna Navqi, for their support and guidance throughout my co-op term
								</p>
							</Col>
						</Row>
					</Container>
				</Container>
			</div>
		</NCR_>
	);
}

export default NCR;
